<template>
    <v-card  height="100%">
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <upload-files :visible="cpFiles.showDialog" :file_type="cpFiles.file_type" :route_type="cpFiles.route_type" :caller="caller" @upload-done="uploadDone" @close="cpFiles.showDialog=false"></upload-files>

        <div class="pa-3">
            <v-toolbar>
            <v-toolbar-title>File Upload List</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
            </v-toolbar>
        </div>
        <div class="pa-3">
        <v-card>
        <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon dark class="primary mr-2" @click="refreshDataTable"><v-icon>mdi-refresh</v-icon></v-btn>         
        <v-btn icon dark class="mb-2 success" @click="showUpload('form.file_name')">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
        </v-card-title>
        <v-card-text>

        <v-text-field
            v-model="tblSearch"
            append-icon="mdi-magnify"
            label="Search data below"
            hide-details
            outlined
        ></v-text-field>
        </v-card-text>

        <v-data-table
        v-model="selected"  
        ref="vuetable"
        item-key="created_date"
        :headers="tblHeaders"
        :items="tblBody"
        :search="tblSearch"
        :loading = "loadingFlag"
        loading-text="Loading... Please wait"    
        @click:row="openDetail"
        ></v-data-table>
        </v-card>
        </div>

    </v-card>

</template>
<script>
//import axios from 'axios';
import DlgMessage from '@/components/DlgMessage'
import UploadFiles from "@/components/UploadFiles";
import ApFileUploadService from "@/services/ApFileUploadService"
export default {
    components:{DlgMessage,UploadFiles},
    props: {
       caller: String
    },
    data() {
        return{
            //breadcrumb
            items: [
              {text:'File Upload',disabled: false,href:'/notification',},
              {text:'List',disabled: false,href:'#',},
              ],
            selected: [],
            tblHeaders:[
                { text:"Name", value:"fu_name" },
                { text:"File Name", value:"file_name" },
                { text:"Process Status", value:"process_status" },
                { text:"Upload Date", value:"created_date" },
                { text:"Upload By", value:"created_by" },
            ],
            tblBody:[],
            tblSearch:'',
            loadingFlag : false,
            errorFlag : false,
            visible:false,
            routeType:"",
            form:{
                route_type:"",
                login_id:'',
                file_name:'',
                file_url:'',
            },
            cpFiles:{
                file_type:'excel',
                route_type:'member_registration',
                showDialog:false,
            },

        }
    },
    async created() {   
    },
    mounted(){
        this.refreshDataTable();
    },
    methods:{
            async refreshDataTable(){
                this.routeType = this.$route.params.route_type;
                this.loadingFlag = true
                this.form.route_type = this.routeType;
                //console.log("form",this.form);
                try{
                    ApFileUploadService.list_file_upload(this.form).then((res) => {
                        //console.log(res);
                        this.tblBody = res.data.data;
                        this.loadingFlag = false;
                        this.errorFlag = false;
                    }).catch((e)=>{
                        //console.log(e.response);
                        this.$refs.dlgMsg.showDlgBox(true,'error','File Upload',e.response.data.error_message);
                        this.loadingFlag = false
                        this.errorFlag = true;
                    })
                    ;
                } catch(e) {
                    this.loadingFlag = false
                    this.$refs.dlgMsg.showDlgBox(true,'error','File Upload','Unknown Error');
                  
                }           
            },
			openDetail(obj){
				//console.log(obj.file_upload_id)
				this.$router.push({ name: 'fileupload-details', 
					params: { 
						file_upload_id: obj.file_upload_id,
					} 
				})

			},

            addRecord(){
                this.visible = true;
            },
            dlgClose(){
                this.visible = false;
                if(!this.errorFlag)
                    this.refreshDataTable();
            },
            showUpload(uploadType){
                //console.log('showupload');
                this.cpFiles.file_type = 'excel';
                this.cpFiles.route_type = this.routeType;
                this.caller = uploadType;
                //console.log(uploadType)
                this.cpFiles.showDialog = true;
            },
            uploadDone(e){
                this.form.file_url = e.fileUrl;
                // //console.log("this." + e.caller + "='" + e.fileName + "'");
                // eval("this." + e.caller + "='" + e.fileName + "'");
                this.refreshDataTable();

            },
    }

}
</script>